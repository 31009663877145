import axios from 'axios'
import cookie from 'cookie'
import Router from 'next/router'

export const serverSideRedirectTo = (pathTo) => {
  const fullPath = pathTo.startsWith('/') ? pathTo : `/${pathTo}`
  return {
    redirect: {
      destination: fullPath,
      statusCode: 302,
    },
  }
}

export const withAuthServerSideProps = (getServerSidePropsFunc) => {
  return async (ctx) => {
    const session = await getSession(ctx)
    if (!session) {
      return serverSideRedirectTo('/login')
    }

    if (getServerSidePropsFunc) {
      return {
        props: { session, data: await getServerSidePropsFunc(ctx, session) },
      }
    }
    return { props: { session, data: { props: { session } } } }
  }
}

export const clearSession = async (req, res) => {
  const cookies = cookie.parse(req.headers.cookie || '')
  res.setHeader(
    'Set-Cookie',
    Object.keys(cookies).map((key) =>
      cookie.serialize(key, '', {
        httpOnly: true,
        secure: process.env.NODE_ENV !== 'development',
        maxAge: 60 * 60,
        sameSite: 'strict',
        path: '/',
      })
    )
  )
}

export const createSession = async (res, jwtData, address) => {
  const cookieObj = {
    ...jwtData,
    address,
    accessTokenDate: new Date().getTime(),
  }

  res.setHeader(
    'Set-Cookie',
    Object.keys(cookieObj).map((key) =>
      cookie.serialize(key, cookieObj[key], {
        httpOnly: true,
        secure: process.env.NODE_ENV !== 'development',
        maxAge: 60 * 60,
        sameSite: 'strict',
        path: '/',
      })
    )
  )
}

export const getSession = async (ctx) => {
  try {
    if (ctx) {
      // Server side
      const serverCookies = { ...ctx?.req?.cookies }
      if (!serverCookies.accessToken || !serverCookies.address) return null
      return serverCookies
    } else {
      // Client side
      const { data } = await axios.get('/api/v1/auth/session')
      return data
    }
  } catch (error) {
    return null
  }
}

export const logout = async (redirectOnLogin = false) => {
  await axios.post('/api/v1/auth/logout')
  if (redirectOnLogin) await Router.replace('/login')
  return false
}
