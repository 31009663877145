import App from 'next/app'

import { useState, useMemo } from 'react'
import { useRouter } from 'next/router'

import { EthereumProvider } from '/src/hooks/useEthereum'
import { SessionProvider } from '/src/hooks/useSession'

import DeviceContext from '/src/contexts/DeviceContext'

import 'rc-tabs/assets/index.css'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import '/src/styles/globals.css'

function _App({ Component, pageProps }) {
  const [isMobile, setIsMobile] = useState(false)
  const router = useRouter()

  const deviceValue = useMemo(() => ({ isMobile, setIsMobile }), [isMobile])

  return (
    <DeviceContext.Provider value={deviceValue}>
      <EthereumProvider>
        <SessionProvider>
          <Component key={router.asPath} {...pageProps} />
        </SessionProvider>
      </EthereumProvider>
    </DeviceContext.Provider>
  )
}

_App.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext)
  return { ...appProps }
}

export default _App
