import { createContext, useEffect, useState, useMemo, useContext } from 'react'

import { getSession, logout } from '/src/helpers/auth'
import EthereumContext from '/src/hooks/useEthereum'

export const SessionProvider = ({ children }) => {
  const [user, setUser] = useState(null)

  const { account } = useContext(EthereumContext)

  useEffect(() => {
    if (account) {
      getSession()
        .then(async (result) => {
          let { address = null } = result || {}
          if (address?.toLowerCase() !== account?.toLowerCase()) {
            await logout()
            address = null
          }
          setUser(address ? { address } : null)
        })
        .catch((e) => setUser(null))
    }
  }, [account])

  const sessionValue = useMemo(() => ({ user, setUser }), [user])

  return (
    <SessionContext.Provider value={sessionValue}>
      {children}
    </SessionContext.Provider>
  )
}

const SessionContext = createContext({
  user: {},
  setUser: () => {},
})

export default SessionContext
