import { createContext, useState, useEffect } from 'react'
import Router from 'next/router'
import toast from 'react-hot-toast'
import getConfig from 'next/config'

import Web3 from 'web3'
import detectEthereumProvider from '@metamask/detect-provider'
import { convertUtf8ToHex } from '@walletconnect/utils'
import WalletConnectProvider from '@walletconnect/web3-provider'
import WalletLink from 'walletlink'

const { publicRuntimeConfig } = getConfig()
const {
  infuraProjectId,
  blockchainNetworkId,
  blockchainProvider,
} = publicRuntimeConfig

const initialState = {
  account: null,
  chainId: 0,
  chainName: '',
  ethereum: null,
}

const walletTypes = {
  metamask: 'metamask',
  walletConnect: 'walletConnect',
  coinbase: 'coinbase',
}

const chainNames = {
  1: {
    name: 'Mainnet',
  },
  4: {
    name: 'Rinkeby',
  },
}

export const EthereumProvider = ({ children }) => {
  const [account, setAccount] = useState(null)
  const [chainId, setChainId] = useState(null)
  const [ethereum, setEthereum] = useState(null)
  const [walletType, setWalletType] = useState(null)

  const requestAccounts = async (provider, type) => {
    if (!provider) {
      toast.error(`Wallet not installed [${type}]`)
      return null
    }
    return new Promise(async (resolve) => {
      try {
        let accounts
        if (type === walletTypes.metamask) {
          accounts = await provider.request({
            method: 'eth_requestAccounts',
          })
        } else if (type === walletTypes.walletConnect) {
          const web3 = new Web3(provider)
          accounts = await web3.eth.getAccounts()
        } else if (type === walletTypes.coinbase) {
          accounts = await provider.send('eth_requestAccounts')
        }
        const currentAccount = accounts[0] || null
        setAccount(currentAccount)
        resolve(currentAccount)
      } catch (error) {
        console.log(error)
        resolve(null)
      }
    })
  }

  const onAccountChange = async () => {
    try {
      const currentWalletType = walletType || walletTypes.metamask
      const { provider } = (await detectProvider(currentWalletType)) || {}
      await requestAccounts(provider, currentWalletType)
    } catch (error) {
      console.log(error)
    }
  }

  const getChainId = async (provider) => {
    try {
      if (provider.isMetaMask) {
        const chainIdHex = await provider.request({ method: 'eth_chainId' })
        return Web3.utils.hexToNumber(chainIdHex)
      } else if (provider.isCoinbaseWallet) {
        throw new Error('Coinbase wallet is not available')
        // return await getChainId(ethereum)
      }
      return null
    } catch (error) {
      console.log('getChainId', error)
      return null
    }
  }

  const initEthereum = async () => {
    if (ethereum) {
      const chainId = await getChainId(ethereum)
      setChainId(chainId)

      if (chainId === Number(blockchainNetworkId)) {
        if (ethereum.selectedAddress) {
          setAccount(ethereum.selectedAddress)
        }
        // await onAccountChange()
      }

      ethereum.removeAllListeners([
        'connect',
        'chainChanged',
        // 'accountsChanged',
      ])

      ethereum.on('accountsChanged', (accounts) => {
        console.log('Account has been changed:', accounts)
        onAccountChange()
      })

      ethereum.on('chainChanged', (chainId) => {
        console.log('Network has been switched:', chainId)
        Router.reload()
      })

      ethereum.on('connect', (connectInfo) => {
        console.log('Wallet connected:', connectInfo)
      })
    }
  }

  const detectProvider = async (walletType) => {
    if (walletType === walletTypes.metamask) {
      return await onInitMetamask()
    } else if (walletType === walletTypes.walletConnect) {
      return await onInitConnectWallet()
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      detectProvider(walletTypes.metamask).then((result) => {
        const { provider = null } = result || {}
        setEthereum(provider)
      })
    }
  }, [])

  useEffect(() => {
    initEthereum()
  }, [ethereum])

  const onInitMetamask = async () => {
    try {
      if (typeof window !== 'undefined') {
        if (!window.ethereum) throw new Error('No extensions detected')
        const provider =
          window.ethereum.providers &&
          typeof window.ethereum.providers === 'object'
            ? window.ethereum.providers.find((provider) => provider.isMetaMask)
            : (await detectEthereumProvider({ mustBeMetaMask: true })) || null

        if (!!provider) {
          console.log('Metamask detected')
          return { provider }
        } else {
          toast.error('Install Metamask extension on your browser')
        }
      }
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const onInitConnectWallet = async () => {
    try {
      const provider = new WalletConnectProvider({
        bridge: 'https://bridge.walletconnect.org',
        infuraId: infuraProjectId,
        qrcode: true,
        qrcodeModalOptions: {
          mobileLinks: [
            'rainbow',
            'metamask',
            'argent',
            'trust',
            'imtoken',
            'pillar',
            'safepal',
          ],
        },
        chainId: Number(blockchainNetworkId),
      })

      if (!!provider) {
        return { provider }
      } else {
        console.log('No wallet detected')
        toast.error('No wallet detected')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onInitCoinbase = async () => {
    const walletLink = new WalletLink({
      appName: 'Rariscan',
      appLogoUrl: 'https://rariscan.io/logo-main.svg',
      darkMode: true,
    })
    const provider = walletLink.makeWeb3Provider(
      blockchainProvider,
      blockchainNetworkId
    )
    return { provider }
  }

  const pause = (timeout) =>
    new Promise((resolve) => setTimeout(resolve, timeout))

  const connectWallet = async (type) => {
    await pause(200)
    if (account) return account
    if (type === walletTypes.metamask) {
      const provider = ethereum || (await onInitMetamask())
      if (!provider) {
        toast.error('Metamask not detected')
        return null
      }
      const networkId = await getChainId(provider)
      if (networkId !== Number(blockchainNetworkId)) {
        console.log('[network error]', networkId, blockchainNetworkId)
        toast.error(
          `Please switch network to ${chainNames[blockchainNetworkId]?.name}`
        )
        return null
      }
      if (!ethereum) setEthereum(provider)
      setWalletType(walletTypes.metamask)
      return await requestAccounts(provider, walletTypes.metamask)

      // if (!ethereum) {
      //   const { provider } = await onInitMetamask()
      //   if (!provider) {
      //     toast.error('Metamask not detected')
      //     return null
      //   }
      //   const networkId = await getChainId(provider)
      //   if (networkId !== blockchainNetworkId) {
      //     toast.error(
      //       `Please switch network to ${chainNames[blockchainNetworkId].name}`
      //     )
      //     return null
      //   }
      //   setEthereum(provider)
      //   setWalletType(walletTypes.metamask)
      //   return await requestAccounts(provider, walletTypes.metamask)
      // }
    } else if (type === walletTypes.walletConnect) {
      const { provider } = await onInitConnectWallet()
      setWalletType(type)
      try {
        await provider.enable()
        setWalletType(walletTypes.walletConnect)
        setEthereum(provider)
        return await requestAccounts(provider, walletTypes.walletConnect)
      } catch (e) {
        if (e.message !== 'User close modal') {
          toast.error('Access denied')
        }
        console.log(e)
        return null
      }
    } else if (type === walletTypes.coinbase) {
      const { provider } = await onInitCoinbase()
      if (provider) {
        try {
          await provider.enable()
          setWalletType(walletTypes.coinbase)
          setEthereum(provider)
          return await requestAccounts(provider, walletTypes.coinbase)
        } catch (e) {
          if (e.message !== 'User close modal') {
            toast.error('Access denied')
          }
          console.log(e)
          return null
        }
      }
    }
    return null
  }

  const disconnectWallet = async () => {
    if (ethereum) {
      try {
        await ethereum.close()
        if (walletType === walletTypes.walletConnect) {
          Router.reload()
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  const personalSign = async (address, message, provider) => {
    if (!address) return ''
    const web3 = new Web3(provider || ethereum)
    return await web3.eth.personal.sign(convertUtf8ToHex(message), address)
  }

  return (
    <EthereumContext.Provider
      value={{
        account,
        chainId,
        ethereum,
        detectProvider,
        connectWallet,
        disconnectWallet,
        personalSign,
      }}
    >
      {children}
    </EthereumContext.Provider>
  )
}

const EthereumContext = createContext(initialState)
export default EthereumContext
